:root{
  --color-menu: #31313e;
  --color-option: #40404f;
  --color-hover: #4f4f60;
}

/* NORMAL MENU */
.Menu a, .Menu a:hover, .Menu a:link, .Menu a:visited{
  color: #eee;
}
.Menu{
  border-right: 1px solid var(--color-menu);
  background-color: var(--color-menu);
}
.Header{
  height: 80px;
  line-height: 80px;
}
.Header h1, .HeaderRes h1{
  position: relative;
  text-transform: uppercase;
  font-size: 24px;
  font-family: 'Catamaran', sans-serif;
  color: rgba(255, 255, 255, 0.8);
  width: 164px;
  margin-left: calc(50% - 159px / 2);
}
.Header h1:before, .HeaderRes h1:before{
  position: absolute;
  z-index: -1;
  content: 'Juan D. Diaz';
  color: rgba(106, 1, 103, 0.8);
  top: 2px;
  left: 4px;
}
.Header h1:after, .HeaderRes h1:after{
  z-index: -1;
  position: absolute;
  content: 'Juan D. Diaz';
  color: rgba(206, 35, 35, 0.8); 
  top: -1px;
  left: 2px;
} 
.menu-title{
  color: #fff;
  font-size: 15px;
  line-height: 18px;
  margin-bottom: 35px;
  font-style: italic;
  padding: 0 16px;
  text-align: center;
}
.Option{
  width: 100%;
  text-align: right;
  padding: 14px;
  font-size: 20px;
  background-color: var(--color-option);
  cursor: pointer;
}
.Option:hover{
  background-color: var(--color-hover);
  transition: border-left 1s;
}
.active-option .Option{
  background-color: #4f4f60;
}
.social-container{
  display: flex;
  width: 100%;
}
.social-item{
  width: 50%;
  cursor: pointer;
}
.Media{
  height: 55px;
  width: 100%;
  font-size: 14px;
  line-height: 45px;
  position: relative;
  cursor: pointer;
  margin-top: 20px;
  text-align: center;
}
.Media img{
  margin-top: 7.5px;
  width: 40px;
}

/* RESPONSIVE MENU */
.MenuResBox a, .MenuResBox a:hover, .MenuResBox a:link, .MenuResBox a:visited{
  color: #eee;
}
.MenuRes{
  border-right: 1px solid var(--color-menu);
  background-color: var(--color-menu);
  height: 60px;
  padding: 0 10px;
  display: none;
  justify-content: space-between;
}
.HeaderRes{
  height: 52px; 
  line-height: 52px;
  padding-left: 10px;
}
.HeaderRes h1 {
  margin-left: 0;
  z-index: 1;
}
.header-title-res{
  padding: 10px 20px;
  line-height: 14px;
  font-size: 12px;
  color: #fff;
}
.ContentLeft{
  display: flex
}
.Opener{
  height: 60px;
  padding: 10px;
  cursor: pointer;
}
.Opener img{
  height: 40px;
}
.MediaRes{
  height: 60px;
  padding: 10px;
  cursor: pointer;
}
.MediaRes img{
  height: 40px;
}
.Accordeon{
  display: none
}
.AccordeonOption{
  width: 100%;
  text-align: right;
  padding: 14px;
  font-size: 20px;
  background-color: var(--color-option);
  cursor: pointer;
}
.active-option .AccordeonOption{
  background-color: #4f4f60;
}
.AccordeonOpen{
  display: block;
}

@media screen and (max-width: 1200px){
  .MenuRes{
    display: flex;
  }
}
@media screen and (max-width: 600px){
  .HeaderRes{
    font-size: 20px;
  }
  .MediaRes{
    padding: 15px 7.5px;
  }
  .MediaRes img{
    height: 30px;
  }
  .Opener{
    padding: 15px;
    padding-left: 7.5px;
  }
  .Opener img{
    height: 30px;
  }
}
