
.me-box{
  max-width: 200px;
  margin-right: 20px;
  margin-bottom: 10px;
  float: left;
}
.me{
  width: 100%;
  border: 1px solid #888;
  border-radius: 3px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
.me-caption{
  text-align: center;
  margin-top: 0px;
  color: #666;
  font-size: 14px;
}
.about-area p{
  margin-top: 0;
  text-align: justify;
  word-wrap: break-word;
  -webkit-hyphens: auto;
  -moz-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto;
}
.skills-title-one{
  text-align: center;
  margin-bottom: 12px;
}
.skills-container{
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  justify-content: space-between;
}
.Skill{
  border: 1px solid #666;
  padding: 0 2.5px;
  flex-basis: calc(50% - 7.5px);
}
.skill-header{
  height: 30px;
  width: calc(100% + 5px);
  margin-left: -2.5px;
  line-height: 30px;
  font-size: 14px;
  text-align: center;
}
.skill-body{
  padding-top: 5px;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.skill-badge{
  flex-basis: 20%;
  text-align: center;
}
.skill-badge img{
  width: 90%;
  margin: 0 auto;
}
.skill-badge p{
  font-size: 9px;
  margin: -2px 0 10px;
  padding: 0;
  text-align: center;
  line-height: 11px;
}
.skills-title-two{
  text-align: center;
  margin-top: 40px;
}
.knowledge-container{
  padding-top: 10px;
}
.knowledge-container ul{
  margin: 0;
}
.knowledge-container ul li{
  line-height: 30px;
}

@media screen and (max-width: 1420px){
  .me-box{
    max-width: 180px;
    margin-bottom: 5px;
  }
  .skill-badge p{
    font-size: 8.5px;
  }
}
@media screen and (max-width: 1200px){
  .skill-badge p{
    font-size: 9px;
  }
}
@media screen and (max-width: 654px){
  .me-box{
    margin-right: 20px;
  }
}
@media screen and (max-width: 572px){
  .me-box{
    max-width: 130px;
    margin-bottom: 5px;
  }
  .me-caption{
    margin-top: -8px;
    font-size: 9.5px;
  }
  .skills-container{
    display: block;
  }
  .Skill{
    margin-bottom: 12px;
  }
  .skill-badge{
    flex-basis: 16.66%;
  }
}
@media screen and (max-width: 520px){
  .knowledge-container ul{
    padding-left: 15px;
  }
}
@media screen and (max-width: 460px){
  .me-box{
    max-width: 120px;
    margin-bottom: 0
  }
}
@media screen and (max-width: 440px){
  .skill-badge{
    flex-basis: 20%;
  }
}
@media screen and (max-width: 382px){
  .skills-title-two{
    font-size: 16.5px;
  }
  .knowledge-container ul{
    font-size: 14px;
  }
}
