.Gallery{
  display: inline-grid;
  grid-template-columns: auto auto;
  grid-column-gap: 5px;
  grid-row-gap: 5px;
  padding: 5px
}
.Tile{
  background-color: #f1f1f1;
  height: 320px;
  min-height: 320px;
  max-height: 320px;
  border: 1px solid #eaeaea;
  position: relative;
}
.tile-bg{
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.tile-category{
  position: absolute;
  top: 10px;
  left: calc(50% - 100px);
  color: #666;
  width: 200px;
  font-size: 13px;
  text-align: center;
}
.tile-footer{
  position: absolute;
  bottom: 10px;
  width: 100%;
  color: #666;
  font-size: 13px;
  text-align: center;
}
.tile-category-light, .tile-footer-light{
  color: #eee;
}
.hovereffect{
  filter: grayscale(1) blur(4px);
  transition: all 0.5s ease-in;
}
.tile-cover{
  background-color: rgba(90, 90, 90, 0.85);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: all 0.5s ease-in;
  opacity: 0
}
.showup{
  opacity: 1;
}
.tile-title{
  background-color: rgba(9,122,181, 0.75);
  line-height: 80px;
  font-size: 22px;
  color: #fff;
  text-align: center;
  width: 70%;
  margin: 0 auto;
  transition: all 0s ease-out;
}
.tile-text{
  width: 85%;
  margin: 30px auto;
  color: #fff;
  height: 100px;
  text-align: center;
}
.tile-title-effect{
  width: 100%;
  transition: all 0.3s ease-in;
}
.tile-details{
  background-color: rgba(20, 20, 20, 0.75);
  border: 1px solid white;
  height: 60px;
  line-height: 60px;
  font-size: 20px;
  text-align: center;
  width: 200px;
  color: white;
  cursor: pointer;
  position: absolute;
  bottom: 35px;
  border-radius: 6px;
  margin-left: calc(50% - 100px)
}
.tile-details:hover{
  box-shadow: 0 0 20px #ffffff;
}

@media screen and (max-width: 900px){
  .tile-text{
    width: calc(100% - 25px);
  }
}
@media screen and (max-width: 800px){
  .Gallery{
    grid-template-columns: auto;
  }
  .tile-text{
    width: 85%;
  }
}
@media screen and (max-width: 500px){
  .tile-text{
    width: calc(100% - 20px);
  }
}
@media screen and (max-width: 480px){
  .Tile{
    height: 255px;
    min-height: 255px;
    max-height: 255px;
  }
  .tile-title{
    line-height: 55px;
    font-size: 25px;
  }
  .tile-details{
    height: 50px;
    line-height: 50px;
    bottom: 20px;
  }
}
