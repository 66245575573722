:root{
  --main-boxes: #eee;
  --main-bar: #ddd;
  --items-color: #097ab5;
}

.Work p{
  text-align: justify;
  word-wrap: break-word;
  -webkit-hyphens: auto;
  -moz-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto;
}
.timeline-paragraph{
  margin-bottom: 40px;
}
.timeline{
  position: relative;
  margin: 0 auto;
  padding-bottom: 22px;
}
.timeline::after{
  content: '';
  position: absolute;
  width: 0px;
  background-color: transparent;
  top: 0;
  bottom: 0;
  left: 50%;
  margin-left: -3px;
  border: 3px dashed var(--main-bar);
}
.experience-container{
  padding: 10px 40px;
  position: relative;
  background-color: inherit;
  width: 50%;
  margin-bottom: -24px;
}
.experience-container::after{
  content: '';
  position: absolute;
  width: 25px;
  height: 25px;
  right: -16.5px;
  background-color: white;
  border: 4px solid var(--items-color);
  top: 15px;
  border-radius: 50%;
  z-index: 1;
}
.left-side{
  left: 0;
}
.right-side{
  left: 50%;
}
.left-side::before{
  content: " ";
  height: 0;
  position: absolute;
  top: 22px;
  width: 0;
  z-index: 1;
  right: 30px;
  border: medium solid var(--main-boxes);
  border-width: 10px 0 10px 10px;
  border-color: transparent transparent transparent var(--main-boxes);
}
.right-side::before{
  content: " ";
  height: 0;
  position: absolute;
  top: 22px;
  width: 0;
  z-index: 1;
  left: 30px;
  border: medium solid var(--main-boxes);
  border-width: 10px 10px 10px 0;
  border-color: transparent var(--main-boxes) transparent transparent;
}
.right-side::after{
  left: -16px;
}
.experience{
  padding: 15px 15px;
  background-color: var(--main-boxes);
  position: relative;
  border-radius: 6px;
}
.experience h3{
  margin: 0 0 2px;
}
.experience h5{
  margin: 0;
}
.experience p{
  margin: 8px auto 0;
  font-size: 16px;
  line-height: 18px;
}
.last-heading{
  font-size: 16px;
  margin-bottom: 25px;
}
.me-box-work{
  max-width: 200px;
  margin: 8px 0 6px 18px;
  float: right;
}
.me-work{
  width: 100%;
  border: 1px solid #888;
  border-radius: 3px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  margin-top: 12px;
}
.me-caption-work{
  text-align: center;
  margin-top: -2px;
  color: #666;
  font-size: 14px;
}

@media screen and (max-width: 600px){
  .timeline::after{
    left: 31px;
  }
  .experience-container{
    width: 100%;
    padding-left: 70px;
    padding-right: 25px;
  }
  .experience-container::before{
    left: 60px;
    border: medium solid white;
    border-width: 10px 10px 10px 0;
    border-color: transparent white transparent transparent;
  }
  .left-side::after, .right-side::after{
    left: 15px;
  }
  .right-side{
    left: 0%;
  }
  .experience-container{
    margin-bottom: 10px;
  }
}
@media screen and (max-width: 492px){
  .timeline-paragraph{
    text-align: justify;
  }
}
@media screen and (max-width: 575px){
  .me-box-work{
    max-width: 180px;
  }
}
@media screen and (max-width: 460px){
  .me-box-work{
    max-width: 160px;
  }
}
@media screen and (max-width: 390px){
  .me-box-work{
    max-width: 130px;
  }
}
