.navigation_pages{
  position: relative;
  height: 50px;
}
.prev_page{
  text-align: left;
  position: absolute;
  width: 120px;
  font-size: 18px;
  left: 20px;
  top: 12px;
  padding: 5px;
}
.next_page{
  text-align: right;
  position: absolute;
  width: 120px;
  font-size: 18px;
  right: 20px;
  top: 12px;
  padding: 5px;
}
.nav_tiny{
  font-size: 12px;
  line-height: 12px;
}
.custom-header{
  width: 25%;
  min-width: 700px;
  min-height: 200px;
  margin: 50px auto 20px;
}
.custom-image{
  width: 100%;
  height: 100px;
  border: 1px solid #eee;
}
.custom-image img{
  object-fit: cover;
  width: 100%;
  height: 100%;
}
.custom-header h1{
  margin: 20px 0 15px;
}
.short-description{ 
  margin-top: 0px;
  line-height: 24px;
  font-weight: normal;
  text-align: justify;
  word-wrap: break-word;
  -webkit-hyphens: auto;
  -moz-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto;
  margin-bottom: 50px;
}
.custom-links{
  text-align: center;
  margin: 0 auto;
  margin-bottom: 50px;
}
a.btn{
  padding: 8px 12px;
  font-size: 16px;
  cursor: pointer;
  border-radius: 3px;
  background-color: #097ab5;
  border: 1px solid #097ab5;
  color: #fff;
  margin: 0 20px;
}
a.btn:hover{
  color: #097ab5;
  background-color: #fff;
}
a.github{
  padding: 8px 12px;
  font-size: 16px;
  cursor: pointer;
  border-radius: 3px;
  width: 160px;
  border: 1px solid #24292e;
  color: #24292e
}
a.github:hover{
  color: #fff;
  background-color: #24292e;
}
.custom-body{
  width: 25%;
  min-width: 700px;
  margin: 0 auto;
  text-align: justify;
  word-wrap: break-word;
  -webkit-hyphens: auto;
  -moz-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto;
}
.gallery-title{
  text-align: center;
  margin-top: 0;
  padding-top: 0;
  margin-bottom: 10px;
}
.gallery-main{
  width: 100%;
  line-height: 0;
  margin-bottom: 50px;
}
.photo-element{
  cursor: pointer;
  display: inline-block;
  width: calc(33.33% - 4px);
  margin: 0 2px;
}
.photo-element:hover > img{
  opacity: 0.5;
}
.gallery-main img {
  margin: 1px 0;
  width: 100% !important;
  height: auto !important;
  border: 1px solid #eee;
}
.content-text{
  margin-bottom: 50px;
  padding: 0
}
.ModalBox{
  left: 0;
  top: 0;
  z-index: 1;
  width: 100%; 
  height: 100%; 
  overflow: auto; 
  position: fixed; 
  background-color: rgb(0,0,0);
  background-color: rgba(0,0,0,0.95);
}
.CarouselBox{
  max-height: calc(100% - 150px);
  height: calc(100% - 150px);
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 30px 50px 40px;
  text-align: center;
  position: relative;
}
.CarouselClose{
  top: 10px;
  right: 10px;
  color: white;
  font-size: 35px;
  position: absolute;
  cursor: pointer
}
.CarouselLegend{
  position: absolute;
  bottom: 20px;
  width: 150px;
  font-size: 12px;
  color: white;
  line-height: 12px;
  text-align: center;
  left: calc(50% - 75px);
}
.CarouselNav{
  padding-bottom: 30px;
  max-width: 900px;
  height: 150px;
  max-height: 150px;
  margin: 0 auto;
  text-align: center;
  justify-content: center;
  align-items: center;
  display: flex;
}
.ModalContent{
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
  border-radius: 3px;
}
.ArrowDirection{
  display: inline-block;
  cursor: pointer;
  color: white;
  border: 1px solid white;
  padding: 10px;
  margin: 0 5px;
  border-radius: 3px;
}
.LittleImage{
  border-radius: 3px;
  margin: 0 5px;
  cursor: pointer;
  max-height: 115px;
  max-width: 150px;
  vertical-align: middle;
}
.ElementActive{
  border: 2px solid #097ab5;
}
.technologies-title{
  text-align: center;
  margin: 30px auto 10px;
}
.technologies-box{
  width: 75%;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  color: #444;
  font-size: 12px;
  justify-content: center;
  margin-bottom: 20px;
}
.technologies-item{
  padding: 2px 12px;
  margin: 2px;
  border-radius: 3px;
  background-color: #eee;
}

@media screen and (max-width: 1000px){
  .CarouselBox{
    max-height: calc(100% - 95px);
    height: calc(100% - 95px);
  }
  .CarouselNav{
    height: 95px;
    width: 100%;
    max-width: 100%;
  }
  .ArrowDirection{
    padding: 10px;
    margin: 0 2.5px;
  }
  .LittleImage{
    margin: 0 2.5px;
    max-height: 65px;
    max-width: 100px;
  }
}
@media screen and (max-width: 740px){
  .custom-header{
    width: 100%;
    min-width: auto;
    padding: 0 20px;
    margin-top: 30px;
  }
  .custom-image{
    margin: 0 0 30px;
  }
  .custom-body{
    width: 100%;
    min-width: auto;
    padding: 0 20px;
  }
}
@media screen and (max-width: 667px){
  .photo-element{
    width: calc(50% - 4px);
  }
  .CarouselBox{
    max-height: calc(100% - 105px);
    height: calc(100% - 105px);
    padding: 50px 10px 40px;
  }
  .CarouselNav{
    padding-bottom: 50px;
    height: 105px;
    width: 100%;
    max-width: 100%;
  }
  .LittleImage{
    margin: 0 2.5px;
    height: 50px;
    width: 50px;
    object-fit: cover;
  }
  .ArrowDirection{
    width: 50px;
    padding: 1px;
    height: 50px;
    line-height: 46px;
    text-align: center;
  }
}
@media screen and (max-width: 600px){
  .technologies-box{
    width: 100%;
  }
}
@media screen and (max-width: 420px){
  .LittleImage{
    margin: 0 2px;
  }
  .ArrowDirection{
    padding: 4px;
    font-size: 12px;
    line-height: 40px;
  }
}