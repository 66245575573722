*{
  box-sizing: border-box;
}
body{
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f8f8f8;
  font-size: 17px;
  line-height: 26px;
}
input, textarea{
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
}
a, a:link, a:hover, a:visited{
  text-decoration: none;
  color: #097ab5;
  cursor: pointer;
}
h1, h2{
  padding: 0;
  margin: 4px 0 12px;
  color: #333;
}
h3, h4, h5{
  padding: 0;
  margin: 4px 0 4px;
  font-size: 18px;
}
h4{
  color: #444;
}
h5{
  color: #666;
}
p{
  color: #444;
}
.App{
  width: 100%;
  height: 100vh;
  display: flex;
}
.Menu{
  position: fixed;
  width: 17%;
  height: 100vh;
}
.Context{
  margin-left: 17%;
  width: 83%;
  padding: 5px;
}

@media screen and (max-width: 1200px){
  .App{
    display: block;
  }
  .Menu{
    display: none
  }
  .MenuRes{
    display: flex;
  }
  .Context{
    margin-left: 0;
    width: calc(100%);
  }
}

.double-column-box{
  border: 1px solid #eaeaea;
  display: flex;
  min-height: 100%;
  background-color: #fff;
  padding: 20px 0px;
  margin-bottom: 5px;
}
.double-column:first-child{
  border-right: 1px solid #ddd;
}
.double-column{
  padding: 0 20px;
  flex-basis: 50%;
}
.single-column-box{
  border: 1px solid #eaeaea;
  display: flex;
  min-height: 100%;
  background-color: #fff;
  padding: 20px 0px;
  margin-bottom: 5px;
  justify-content: space-around;
}
.single-column{
  padding: 0 20px;
  flex-basis: 70%;
}
.dynamic-box{
  border: 1px solid #eaeaea;
  min-height: 100%;
  background-color: #fff;
}

@media screen and (max-width: 1320px){
  .double-column{
    padding: 0 10px;
  }
}
@media screen and (max-width: 1200px){
  .double-column{
    padding: 0 20px;
  }
}
@media screen and (max-width: 1100px){
  .double-column{
    padding: 0 10px;
  }
}
@media screen and (max-width: 1050px){
  .double-column-box{
    display: block;
  }
  .double-column{
    padding: 0 200px;
  }
  .double-column:first-child{
    border-right: none;
    margin-bottom: 50px;
  }
}
@media screen and (max-width: 954px){
  .double-column{
    padding: 0 150px;
  }
}
@media screen and (max-width: 854px){
  .double-column{
    padding: 0 100px;
  }
}
@media screen and (max-width: 754px){
  .double-column{
    padding: 0 50px;
  }
  .single-column{
    flex-basis: 82%;
  }
}
@media screen and (max-width: 654px){
  .double-column{
    padding: 0 20px;
  }
  .single-column{
    flex-basis: 92%;
  }
}
@media screen and (max-width: 580px){
  .single-column{
    flex-basis: calc(100% - 10px);
  }
}
@media screen and (max-width: 492px){
  body{
    font-size: 15px;
    line-height: 22px;
  }
}
